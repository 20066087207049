import { APIUser } from '@betablocks/shared/lib/api/users'
import React from 'react'

export const UserContext = React.createContext<{ user: APIUser | null }>({ user: null })

type Props = React.PropsWithChildren<{
  user: APIUser
}>

const UserProvider: React.FC<Props> = ({ children, user }) => {
  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
}

export default UserProvider
